/*
* Lists
* ================ */
[class*="list-inline"] a {
  transition: none;
  text-decoration: none !important; }

.list-inline-1 {
  counter-reset: li; }
  .list-inline-1 > li {
    text-align: left; }
    .list-inline-1 > li.list-counter {
      display: block;
      position: relative;
      padding: 0.6875em;
      padding-left: 3.75em;
      border-radius: 50px;
      transition: all .4s ease; }
      .list-inline-1 > li.list-counter + .list-counter {
        margin-top: 4px; }
      .list-inline-1 > li.list-counter:nth-child(1) > .before {
        background: var(--brand-primary); }
      .list-inline-1 > li.list-counter:nth-child(2) > .before {
        background: var(--brk-base-3); }
      .list-inline-1 > li.list-counter:nth-child(3) > .before {
        background: var(--brk-secondary-4); }
      .list-inline-1 > li.list-counter:nth-child(4) > .before {
        background: var(--brk-secondary-3); }
      .list-inline-1 > li.list-counter:nth-child(5) > .before {
        background: var(--secondary); }
      .list-inline-1 > li.list-counter:hover {
        box-shadow: 0 4px 10px 0 rgba(165, 165, 165, 0.5); }
        .list-inline-1 > li.list-counter:hover:after {
          opacity: 1; }
      .list-inline-1 > li.list-counter > .before {
        color: #fff;
        background: #C423EE;
        font-size: 1em;
        font-weight: 700;
        font-family: 'Montserrat', sans-serif;
        line-height: 1;
        width: 2.1875em;
        height: 2.1875em;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 8px;
        display: flex;
        justify-content: center;
        align-items: center; }
        .list-inline-1 > li.list-counter > .before:before {
          content: counter(li);
          counter-increment: li; }
      .list-inline-1 > li.list-counter:after {
        content: '\f105';
        font-family: "Font Awesome\ 5 Free";
        font-weight: 700;
        font-size: 16px;
        color: #C9C9C9;
        position: absolute;
        top: 50%;
        right: 24px;
        transform: translateY(-50%);
        transition: all .4s ease;
        opacity: 0; }

.list-inline-2 li {
  display: block;
  text-align: left; }
  .list-inline-2 li:hover .cirlce > .after {
    width: 30px;
    height: 30px; }
  .list-inline-2 li:hover .cirlce > .before {
    background: #fff; }
  .list-inline-2 li .text {
    display: inline-block;
    padding: 0.4375em;
    padding-left: 3.125em;
    position: relative;
    z-index: 1; }
  .list-inline-2 li .cirlce {
    display: inline-block;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 14px;
    height: 14px; }
    .list-inline-2 li .cirlce > .after {
      content: '';
      position: absolute;
      width: 0px;
      height: 0px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #f1f6ff;
      border-radius: 50%;
      z-index: -1;
      box-shadow: 0 0 10px 0 rgba(var(--brand-primary-rgb), 0.32);
      transition: all .4s ease; }
    .list-inline-2 li .cirlce > .before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: var(--brand-primary);
      border-radius: 50%;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.26);
      transition: all .4s ease; }
  .list-inline-2 li + li {
    margin-top: 4px; }

.list-inline-3 {
  border: 1px solid #ECECEC;
  margin-left: 1.625em;
  margin-right: 1.625em; }
  .list-inline-3 li {
    display: block;
    padding: 0.875em;
    padding-left: 1.875em;
    position: relative;
    z-index: 1;
    transition: all .4s ease; }
    @media (min-width: 992px) {
      .list-inline-3 li {
        text-align: left; } }
    .list-inline-3 li + li {
      border-top: 1px solid #ECECEC; }
    @media (min-width: 768px) {
      .list-inline-3 li {
        padding-left: 3.4375em; } }
    .list-inline-3 li > .before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0.9375em;
      width: 8px;
      height: 8px;
      background: var(--brand-primary);
      border-radius: 50%; }
      @media (min-width: 768px) {
        .list-inline-3 li > .before {
          left: 1.5625em; } }
    .list-inline-3 li > .after {
      position: absolute;
      top: 0;
      left: -1.625em;
      right: -1.625em;
      bottom: 0;
      border-radius: 50px;
      background: var(--brand-primary);
      z-index: -1;
      transform: scale(0.95);
      transition: all .4s ease;
      opacity: 0; }
    .list-inline-3 li:hover {
      color: #fff; }
      .list-inline-3 li:hover a {
        color: #fff; }
      .list-inline-3 li:hover > .after {
        transform: scale(1);
        opacity: 1; }

.list-inline-4 li {
  display: block;
  text-align: left;
  border: 1px solid #eee;
  border-radius: 50px;
  padding: 0.875em;
  padding-left: 3.4375em;
  position: relative;
  z-index: 1;
  transition: all .4s ease; }
  .list-inline-4 li + li {
    margin-top: 10px; }
  .list-inline-4 li:hover {
    color: #fff;
    box-shadow: 0 5px 16px 0 rgba(165, 165, 165, 0.5); }
    .list-inline-4 li:hover a {
      color: #fff; }
    .list-inline-4 li:hover > .before {
      background: transparent;
      width: 14px;
      height: 14px; }
    .list-inline-4 li:hover > .after {
      opacity: 1; }
  .list-inline-4 li > .before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1.25em;
    width: 16px;
    height: 16px;
    border: 2px solid #fff;
    border-radius: 50%;
    transition: all .4s ease;
    background: var(--brand-primary); }
  .list-inline-4 li > .after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50px;
    background: linear-gradient(to right, var(--brand-primary), var(--secondary));
    z-index: -1;
    transition: all .4s ease;
    opacity: 0; }
  .list-inline-4 li .icon {
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    color: #fff;
    z-index: 2; }

.list-inline-5 {
  counter-reset: li; }
  .list-inline-5 > li {
    overflow: hidden; }
    .list-inline-5 > li.list-counter {
      display: block;
      position: relative;
      z-index: 1;
      background: #fff;
      border: 1px solid #eee;
      padding: 0.875em 2.5em;
      border-radius: 50px;
      transition: all .4s ease;
      text-align: center; }
      .list-inline-5 > li.list-counter + .list-counter {
        margin-top: 10px; }
      .list-inline-5 > li.list-counter:hover {
        color: #fff; }
        .list-inline-5 > li.list-counter:hover a {
          color: #fff; }
        .list-inline-5 > li.list-counter:hover > .before {
          color: #fff; }
        .list-inline-5 > li.list-counter:hover > .after {
          opacity: 1; }
        .list-inline-5 > li.list-counter:hover .list-shape {
          opacity: 1; }
      .list-inline-5 > li.list-counter > .before {
        color: #BDBDBD;
        font-size: 1em;
        font-family: 'Montserrat', sans-serif;
        line-height: 1;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: all .4s ease;
        left: 25px; }
        .list-inline-5 > li.list-counter > .before:before {
          content: "0" counter(li);
          counter-increment: li; }
      .list-inline-5 > li.list-counter > .after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 50px;
        background: linear-gradient(to right, var(--brand-primary), var(--secondary));
        z-index: -1;
        transition: all .4s ease;
        opacity: 0; }
      .list-inline-5 > li.list-counter .list-shape {
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        pointer-events: none;
        opacity: 0;
        transition: all .4s ease; }

.list-inline-6 {
  border: 1px solid #ECECEC; }
  .list-inline-6 li {
    display: block;
    padding: 0.875em;
    padding-left: 1.625em;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: all .4s ease; }
    @media (min-width: 992px) {
      .list-inline-6 li {
        text-align: left; } }
    .list-inline-6 li + li {
      border-top: 1px solid #ECECEC; }
    .list-inline-6 li:before {
      content: '\f105';
      font-family: "Font Awesome\ 5 Free";
      font-weight: 700;
      font-size: 16px;
      color: #C9C9C9;
      position: absolute;
      z-index: 2;
      top: 50%;
      right: 1.5em;
      transform: translateY(-50%);
      transition: all .4s ease; }
    .list-inline-6 li > .after {
      content: '';
      position: absolute;
      top: 0;
      right: -30px;
      width: 0;
      height: 100%;
      transform: skewX(-35deg);
      transition: all .4s ease;
      background: var(--brand-primary);
      z-index: -1; }
    .list-inline-6 li:hover:before {
      color: #fff; }
    .list-inline-6 li:hover > .after {
      width: 90px; }

.list-check li {
  position: relative;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  padding-left: 34px; }
  .list-check li + li {
    margin-top: 23px; }
  .list-check li:before {
    content: '';
    position: absolute;
    top: 5px;
    left: 10px;
    width: 2px;
    height: 15px;
    background: #fff;
    transform: rotate(32deg); }
  .list-check li:after {
    content: '';
    position: absolute;
    top: 12px;
    left: 4px;
    width: 2px;
    height: 8px;
    background: #fff;
    transform: rotate(-55deg); }

.list-check_base-color li:before,
.list-check_base-color li:after {
  background: var(--brk-base-2); }

.list-check_tiny li + li {
  margin-top: 0; }

.list-angle li {
  position: relative;
  padding-left: 21px; }
  .list-angle li::before, .list-angle li::after {
    content: '';
    position: absolute;
    left: 0;
    height: 1px;
    width: 5px;
    background-color: var(--brand-primary); }
  .list-angle li::before {
    transform: rotate(45deg);
    top: 12px; }
  .list-angle li::after {
    transform: rotate(-45deg);
    top: 15px; }
